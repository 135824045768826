import React, { useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa"
// Components
import WrapperRight from "../components/WrapperRight"
import ArticleCard from "../components/ArticleCard"
import { SectionTitle } from "../components/styled"
import ReactPaginate from "react-paginate"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

const Info = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiTitles(sort: { order: DESC, fields: DatePublication }) {
        nodes {
          Content
          Title
          Description
          Slug
          Image {
            url
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }
    }
  `)
  const dataArticles = query.allStrapiTitles.nodes
  const [postsPerPage] = useState(10)
  const [offset, setOffset] = useState(1)
  const [allDataArticle, setAllDataArticle] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)

  const getPostData = data => {
    return data.map(news => <ArticleCard article={news} />)
  }

  const getAllPosts = async () => {
    const data = await dataArticles
    const indexLast = postsPerPage * selectedPage
    const indexFirst = indexLast - postsPerPage
    const slice = data.slice(indexFirst, indexLast)
    const postData = getPostData(slice)
    setAllDataArticle(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }

  const handlePageClick = e => {
    setSelectedPage(e.selected + 1)
    setOffset(selectedPage * 10)
  }

  useEffect(() => {
    getAllPosts()
    window.scrollTo(0, 0) //Do scroll up when press the number button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return (

      <section className="container wrapper">
        <div className="row">
          <div className="wrapper__left col-lg-9 col-lg-9">
            <div
              className="article-listing__title"
              style={{ paddingBottom: "0" }}
            >
              <SectionTitle>記事一覧</SectionTitle>
              <hr className="u-line-bottom" />
            </div>
            {allDataArticle}
            <ReactPaginate
              previousLabel={<FaAngleDoubleLeft />}
              nextLabel={<FaAngleDoubleRight />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"content-pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <WrapperRight />
        </div>
      </section>

  )
}

export default Info


export const Head = () => {
  return (
    <Seo
      title="記事一覧"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/info`}      
    />
  )
}
